import * as React from "react"
export default class Logo extends React.Component {
    render() {
        return (
            <svg
            className={`emblem z-4 ${this.props.className}`}
            viewBox="0 0 30 55"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path d="M22.5944 2.8366H21.0145L19.8834 5.63732H18.097L19.5423 1.45421H19.5333H17.9624L16.5172 5.63732H15.7452V0H14.255V5.63732H13.483L12.0468 1.4632H10.4669L11.9121 5.63732H10.1168L8.98574 2.84559H7.39686H7.37891L9.11141 7.12747H12.4238H17.5854H20.8888L22.6213 2.84559L22.5944 2.8366Z"></path>
            <path d="M30 9.79358H0V39.1832C0 39.2101 0 39.2281 0 39.255C0 39.2819 0 39.2999 0 39.3268V39.3537C0.05386 47.5763 6.75942 54.255 14.991 54.255C23.2316 54.255 29.9282 47.5763 29.982 39.3537H29.991V9.79358H30ZM15.7451 11.2837H18.2495C17.9892 11.4453 17.7379 11.6158 17.5045 11.8043C16.8043 12.3609 16.2208 13.0252 15.754 13.7613V11.2837H15.7451ZM14.2549 24.1473H2.55834L14.2549 12.5315V24.1473ZM15.7451 22.72C16.0413 23.2137 16.3555 23.6894 16.6876 24.1473H15.7451V22.72ZM16.5889 21.185C15.1885 18.4561 15.9785 14.9283 18.4291 12.9714C21.4093 10.5925 25.8977 11.7325 28.5099 14.1742V24.1473H18.5817C17.8187 23.2227 17.1364 22.2442 16.5889 21.185ZM28.5099 25.6464V34.1832C27.7109 32.9803 26.6786 32.0108 25.6643 31.0593C25.4578 30.8618 25.2424 30.6643 25.0359 30.4579C24.3896 29.8385 23.7074 29.2191 23.0521 28.6177C21.9928 27.6572 20.9246 26.6787 19.9192 25.6374H28.5099V25.6464ZM13.3932 11.2837C10.9515 13.7074 4.29982 20.3142 1.4991 23.097V11.2837H13.3932ZM1.4991 32.7289V25.6464H8.70736H14.2549V52.738C7.18132 52.352 1.53501 46.4902 1.4991 39.3268V39.1921V32.7289ZM15.7451 52.729V25.6464H17.8905C19.1741 27.1096 20.6194 28.4291 22.0467 29.7308C22.693 30.3232 23.3662 30.9337 24.0036 31.5441C24.219 31.7505 24.4345 31.948 24.6409 32.1545C26.7056 34.1024 28.4919 35.781 28.4919 39.255C28.5009 46.4453 22.8456 52.343 15.7451 52.729ZM28.5099 11.2837V12.2263C28.0521 11.8852 27.5763 11.571 27.0826 11.2837H28.5099Z"></path>
          </svg> 
        )}
}
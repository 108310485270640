import * as React from "react"

export default class MenuButton extends React.Component {
  componentDidMount() {
    
  }
  render() {
    return (
      <button className="relative h-16 w-18 c-p flex-end-br flex" id="menu-button" onClick={this.props.openMenu}>
        <div className="b-t-b-2-w  w-77-p h-100-p flex flex-end-br" id="top-bars">
        
          <div className="h-7 b-2-w absolute w-100-p t-w " id="middle-bar">

          </div>
        
        </div>
      </button>
    )
  }
}

import React from "react"
import TransitionLink from "gatsby-plugin-transition-link"
import gsap from "gsap"

export default class PageLinkFade extends React.Component {
  exit(exit, node) {

    let tl = gsap.timeline()
    tl.to("body", { backgroundColor: "white", duration: 0 })
    tl.to(node.querySelector("body"), {
      position: "fixed",
      maxHeight: "100vh",
    })

    tl.to(
      node.querySelector(".app"),
      { opacity: 0, duration: 1, ease: "power1.out" },
      "<"
    )

    tl.to(node.querySelector(".app"), { visibility: "hidden" })
  }
  enter(entry, node) {
    let tl = gsap.timeline()
    tl.to("body", { backgroundColor: "white", duration: 0 })
    tl.to(node.querySelector(".app"), { opacity: 0, duration: 0 })
    tl.to(node.querySelector("body"), {
      position: "unset",
      maxHeight: "unset",
      duration: 0
    })
    tl.to(node.querySelector(".app"), { visibility: "visible", duration: 0 })


    tl.to(
      node.querySelector(".app"),
      { opacity: 2, duration: 3, ease: "ease" },

    )

      tl.to("body", {
        position: "relative",
        maxHeight: "unset",
        height:"unset",
        overflow:"unset",
        duration: 0
      })
    
    tl.to("body", { backgroundColor: "black", duration: 0 })


  }

  render() {
    const windowReload = (e) =>{
      if(window.location.pathname === this.props.to){
        e.preventDefault()
        window.location.reload()
      }
    }
    return (
      <TransitionLink
        onClick={
          windowReload
        }
        to={this.props.to}
        id="page-tran-fade"


        className={`c-p ` + this.props.className}

        data-target={this.props.target}
        exit={{
          target: this.props.target,
          length: 1,
          trigger: ({ exit, node }) => {
            this.exit(exit, node)

          },
        }}
        entry={{
          state: { internal: true },
          delay: 2,
          length: 3,
          trigger: ({ entry, node }) => {
            this.enter(entry, node)

          },
        }}

      >
        {this.props.children}
      </TransitionLink>
    )
  }
}

import * as React from "react"

export default class Two4Seven extends React.Component {
  render() {
    return (

      <svg
        id="arrow"
        className={`${this.props.className}`}
        width="7"
        height="6"
        viewBox="0 0 7 6"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
       
      >
        <path d="M5.51426 2.97491L5.88384 3.54839H0V2.45161H5.88384L5.51426 3.02509L3.42978 0.767025L4.27244 0L7 3.00358L4.27244 6L3.42978 5.23298L5.51426 2.97491Z" />
      </svg>

    )
  }
}

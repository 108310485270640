import * as React from "react"

export default class Cross extends React.Component {
  render() {
    return (
    <button onClick={this.props.close} className="absolute  h-16 w-18 z-6 o-p-0 c-p no-events l-4" id="cross">

       
      <svg
        className=""
        width="15"
        height="15"
        viewBox="0 0 15 15"
        fill="white"
        
        xmlns="http://www.w3.org/2000/svg"

      >
        <path d="M1.27208 1.27208L14 14" stroke="white" />
        <path d="M1.00059 13.7279L13.7285 1" stroke="white" />
      </svg>
      </button>
    )
  }
}

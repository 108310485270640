import * as React from "react"
import { useStaticQuery, graphql } from "gatsby"
const Footer = ({ classNameText, className }) => {
  const { datoCmsFooterMarquee } = useStaticQuery(
    graphql`
      query {
        datoCmsFooterMarquee {
          marquee {
            marqueeText
          }
        }
      }
    `
  )
  const { marquee } = datoCmsFooterMarquee
  return (
    <div className={"footer p10 pl0 pr0 text-center bt1 flex-inline " + className} id='slider-footer'>
      <div className='marquee text-center flex-inline'>
        <div className='marquee-inner nowrap flex-inline'>
          <div className='marquee-word-wrap flex-inline'>
            <div className='flex flex-r m-w-150vw br'>
              {marquee.map(item => (
                <h4 key={item.marqueeText} className={"m0 line up flex flex-center-h m-r-auto m-l-0 " + classNameText}>{item.marqueeText}</h4>
              ))}
            </div>
          </div>
          <div className='marquee-word-wrap flex-inline'>
            <div className='flex flex-r m-w-150vw br'>
              {marquee.map(item => (
                <h4 key={item.marqueeText} className={"m0 line up flex flex-center-h m-r-auto m-l-0 " + classNameText}>{item.marqueeText}</h4>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Footer

import * as React from "react"
import Arrow from "../components/Arrow"

import PageLinkFade from "../components/PageTransitionFade"
export default class LinktoFormButton extends React.Component {
  constructor(props) {
    super(props)
    this.hover = this.hover.bind(this)
    this.leave = this.leave.bind(this)
  }

  hover(section) {
    const arrow = section.querySelector("#arrow")
    const button = section.querySelector("div")

    if (arrow && button) {
      button.classList.add("m-a-1-e-0")
      arrow.classList.replace("f-i-1-b", "f-i-1-e")
    }
  }
  leave(section) {
    const arrow = section.querySelector("#arrow")
    const button = section.querySelector("div")

    if (arrow && button) {
      arrow.classList.replace("f-i-1-e", "f-i-1-b")
      button.style.translate = `transform 1 ease `
      button.classList.remove("m-a-1-e-0")
    }
  }

  render() {
    let button
    if (this.props.submitContact) {
      button = (
        <PageLinkFade to={this.props.to} id='submit-button-enter' className={`flex flex-center-v c-p all-events   ${this.props.className}`}>
          <Arrow className='f-2A2A2A r-b' />
          <div className='p-l-10  button c-2A2A2A'> {this.props.text}</div>
        </PageLinkFade>
      )
    } else if (this.props.membership) {
      button = (
        <PageLinkFade to={this.props.to}>
          <div
            role='button'
            tabIndex={0}
            className={`flex flex-center-v relative  m-b-br-140 ${this.props.className}`}
            onMouseEnter={e => {
              if (window.innerWidth > 768) {
                this.hover(e.target.parentNode)
              }
            }}
            onMouseLeave={e => {
              if (window.innerWidth > 768) {
                this.leave(e.target.parentNode)
              }
            }}
          >
            <Arrow className={`f-2A2A2A relative br c-p all-events    ${this.props.classNameArrow}`} />
            <div className={`button c-2A2A2A trans p-l-10  ${this.props.classNameDiv}`}>{this.props.text}</div>
          </div>
        </PageLinkFade>
      )
    } else {
      button = !this.props.isLink ? (
        <a
          onClick={() => window.lintrk("track", { conversion_id: 14585460 })}
          href={`mailto:tessa@two4seven.com.au?cc=jai%40two4seven.com.au%20&subject=${this.props.emailSubject}&body=${this.props.emailBody}`}
          className={`flex flex-center-v relative z-7  all-events m-b-br-140 li-conversion  ${this.props.className}`}
          onMouseEnter={e => {
            if (window.innerWidth > 768) {
              this.hover(e.target.parentNode)
            }
          }}
          onMouseLeave={e => {
            if (window.innerWidth > 768) {
              this.leave(e.target.parentNode)
            }
          }}
        >
          <Arrow className={`f-2A2A2A absolute r-b ab-l-0 f-i-1-b  ${this.props.classNameArrow}`} />
          <div className={`button c-2A2A2A trans ${this.props.classNameDiv}`}>{this.props.text}</div>
        </a>
      ) : (
        <PageLinkFade to={this.props.to}>
          <button
            to={this.props.to}
            className={`flex flex-center-v relative z-7  all-events m-b-br-140  ${this.props.className}`}
            onMouseEnter={e => {
              if (window.innerWidth > 768) {
                this.hover(e.target.parentNode)
              }
            }}
            onMouseLeave={e => {
              if (window.innerWidth > 768) {
                this.leave(e.target.parentNode)
              }
            }}
          >
            <Arrow className={`f-2A2A2A absolute r-b ab-l-0 f-i-1-b  ${this.props.classNameArrow}`} />
            <div className={`button c-2A2A2A trans ${this.props.classNameDiv}`}>{this.props.text}</div>
          </button>
        </PageLinkFade>
      )
    }
    return (
      <div className='z-6  ' id='linkMembership'>
        {button}
      </div>
    )
  }
}
